@font-face {
  font-family: "Batangas";
  src: url(Batangas\ Bold\ 700.otf);
}

body {
  background-color: #082032;
  color: #fff;
}

html {
  scroll-behavior: smooth;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.header {
  color: #ff914d;
  font-family: "Batangas";
  font-size: 80px;
  margin: 0;
}

.icon {
  color: #fff;
  padding: 10px;
  position: relative;
  transition: 0.2s;
  border-radius: 50%;
}

.social:hover .icon {
  color: #ff914d;
}

.social .icon:hover {
  color: #ff914d;
}

.scroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 20%;
  color: #fff;
  transition: 0.2s;
  text-decoration: none;
}

.scroll:hover {
  color: #ff914d;
}

.separator {
  width: 100%;
  height: 2px;
  background-color: #061825;
}

.section {
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.sectionHeader {
  color: #fff;
  margin-bottom: 5%;
}

.projectsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.worksContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.work {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 17px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  border-radius: 14px;
  overflow: hidden;
  width: 90%;
  max-width: 480px;
}

.workName {
  width: calc(100% - 30px);
  background-color: #061825;
  border-radius: 14px 14px 0 0;
  padding: 15px;
  z-index: 5;
}

.workImage {
  width: 100%;
  height: auto;
  border-radius: 0 0 14px 14px;
}

.workContent {
  width: 100%;
  height: 100%;
  gap: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: .2s;
  background-color: #05131e;
  top: -100%;
}

.workDescription {
  font-weight: 600;
}

.workButton {
  background-color: transparent;
  border: 2px solid #ff914d;
  padding: 8px 18px;
  border-radius: 100px;
  cursor: pointer;
  transition: .2s;
  font-weight: 600;
  font-size: 15px;
  font-family: "Nunito";
  color: #fff;
  text-decoration: none;
}

.workButton:hover {
  background-color: #ff914d;
}

.technologiesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.technologies {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.technology {
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, .4);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, .2);
}

.work:hover .workContent {
  top: 0;
}

.project {
  flex-grow: 1;
  display: flex;
  flex-basis: 170px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #fff;
  font-size: 20px;
  margin: 10px;
  transition: 0.2s;
  cursor: pointer;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7px );
  -webkit-backdrop-filter: blur(7px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.project:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.projectName {
  padding: 10px;
  cursor: pointer;
}

.projectImage {
  border-radius: 20px;
}

.skillsWrapper {
  background-color: rgba(0, 0, 0, 0.2);
  width: 760px;
  height: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 20px;
}

.skill {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #444 linear-gradient(to right, transparent 50%, var(--clr) 0);
}

.skill::before {
  content: "";
  display: block;
  height: 100%;
  margin-left: 50%;
  transform-origin: left;
  border-radius: 0 100% 100% 0 / 50%;
  background: var(--clr);
  transform: rotate(calc((var(--i) - 50) * 0.01turn));
}

.skill::after {
  content: "";
  position: absolute;
  inset: 10px;
  border-radius: 50%;
  background-color: #222;
}

.skillName {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.skillPercentageText {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 160%;
  font-weight: 600;
  z-index: 10;
}

.footer {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerText {
  color: #fff;
}

.footerName {
  color: #ff914d;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 10px;
  text-decoration: none;
}

.footerName:hover {
  padding: 5px;
  background-color: #ff914d;
  color: #fff;
}

.socials {
  margin-top: 25px;
}

::-webkit-scrollbar {
  width: auto;
}

::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border: 5px solid #082032;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #717171;
}

@media (max-width: 700px) {
  .header {
    font-size: 60px;
  }
}

@media (max-width: 858px) {
  .projectsHeader {
    position: relative;
    padding: 40px;
  }
}